
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsSlider extends Vue {

    @Prop({ type: Number, required: true })
    private readonly value!: number;

    @Prop({ type: Number, default: 100 })
    private readonly max!: number;

    private get percentage() {
        return (100 / this.max) * this.value;
    }

}

export default VsSlider;
