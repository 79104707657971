
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsLoading extends Vue {
    @Prop({ type: String, default: 'white' })
    public color!: string;

    @Prop({ type: String, default: '' })
    public blockingStyle!: string;

    @Prop({ type: String, default: '' })
    public messageStyle!: string;

    @Prop({ type: String, default: 'Atualizando' })
    public message!: string;

    @Prop({ type: String, default: '8px' })
    public size!: string;
}

export default VsLoading;
