
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsFlatButton } from '../vs-flat-button';
import { VsIcon } from '../vs-icon';

@Component({ components: { VsFlatButton, VsIcon } })
class VsToolbarItem extends Vue {

    @Prop({ type: String })
    private readonly icon?: string;
    
    @Prop({ type: String, default: '16px' })
    private readonly size!: string;
   
    @Prop({ type: String, default: 'white' })
    private readonly color!: string;

}

export default VsToolbarItem;
