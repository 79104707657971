
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsIcon } from '../vs-icon';
import { VsLoading } from '../vs-loading';

// @ts-ignore
import { mask } from 'ke-the-mask';

@Component({ components: { VsIcon, VsLoading }, directives: { mask } })
class VsLoginInput extends Vue {
    private mounted(){
        this.input = this.$refs.input;
    }

    @Prop({ type: String, default: '' })
    private rules!: string;

    @Prop({ type: String, required: true })
    private name!: string;

    @Prop({ type: String, required: true })
    private value!: string;

    @Prop({ type: String })

    @Prop({ type: String, default: 'text' })
    private type!: string;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    @Prop({ type: String, default: '' })
    private mask!: string;

    @Prop({ type: String, required: true })
    private placeholder!: string;

    @Prop({ type: Boolean, default: false })
    private showSubmit!: boolean;

    @Prop({ type: Boolean, default: false })
    private loading!: boolean;

    @Prop({ type: Boolean, default: false })
    private inError!: boolean;

    private input: any = null;

    private reset() {
        if(this.input != null) {
            this.input.reset();
        }
    }

    private get text(): string {
        this.reset();
        return this.value;
    }
    private set text(value: string) {
        this.$emit('input', value);
    }
}

export default VsLoginInput;
