import EventCancelDTO from '@/app/DTOs/scheduling/EventCancelDTO';
import EventDetailsDTO from '@/app/DTOs/scheduling/EventDetailsDTO';
import CreatedDTO from '@/modules/core/DTOs/CreatedDTO';
import CreateEventDTO from '@/modules/scheduling/DTOs/events/CreateEventDTO';
import EventDTO from '@/modules/scheduling/DTOs/events/EventDTO';
import httpPilotar from '@/plugins/httpPilotar';
import { Get, Post } from '@/services/BaseService';

export default class AppSchedulingService {

    public static getSchedulingEventDetails(id: string): Promise<EventDetailsDTO>{
        return Get<EventDetailsDTO>(`/app/scheduling/get-event-details`, { "id": id }, { customClient: httpPilotar });
    }

    public static cancelEvent(id: string, willLoseCredits: boolean) {
        return Post<EventCancelDTO, string>(`/app/scheduling/cancel-event`, { "id": id, "willLoseCredits": willLoseCredits, "origin": "Aluno" }, httpPilotar);
    }

    public static createEvent(event: CreateEventDTO) {
        return Post<CreateEventDTO, CreatedDTO>(`/app/scheduling/create-event`, event, httpPilotar);
    }  

    public static checkCredits() {
        return Post(`/app/scheduling/check-credits`, null, httpPilotar);
    }  

    public static getEventsBySession(): Promise<EventDTO[]>{
        return Get<EventDTO[]>(`/app/scheduling/get-events-by-session`, { }, { customClient: httpPilotar });
    }
} 