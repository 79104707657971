
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { VsFlatButton } from '../vs-flat-button';

// @ts-ignore
import vClickOutside from 'v-click-outside';

@Component({
    components: { VsFlatButton },
    directives: { 'clickOutside': vClickOutside.directive }
})
class VsDropdown extends Vue {
    
    @Prop({ type: Boolean, default: false })
    private readonly disabled!: boolean;

    @Prop({ type: Number, default: 15 })
    private readonly arrowPadding!: number;

    @Prop({ type: String, default: 'bottom-right' })
    private readonly position!: string;

    @Prop({ type: Boolean, default: false })
    private readonly closeOnClick!: boolean;

    @Prop({ type: Boolean, default: true })
    private readonly closeOnClickOut!: boolean;

    @Prop({ type: Boolean, default: true })
    private readonly showArrow!: boolean;
   
    private active: boolean = false;

    private toogle() {
        if(!this.disabled) {
            this.active = !this.active;
        }
    }

    private clickOut(): void {
        if(this.closeOnClickOut) {
            this.active = false;
        }
    }

    private get contentPosition() {
        switch (this.position) {
            case 'top-left':
                return { bottom: 'calc(100% + 5px)', left: '0' };
            case 'top-right':
                return { bottom: 'calc(100% + 5px)', right: '0' };
            case 'bottom-left':
                return { top: 'calc(100% + 5px) ', left: '0' };
            case 'bottom-right':
                return { top: 'calc(100% + 5px)', right: '0' };
            default:
                return { left: '0' };
        }
    }

    private get arrowPosition() {
        switch (this.position) {
            case 'top-left':
                return { bottom: '-5px', left: this.arrowPadding +'px' };
            case 'top-right':
                return { bottom: '-5px', right: this.arrowPadding +'px' };
            case 'bottom-left':
                return { top: '-5px ', left: this.arrowPadding +'px' };
            case 'bottom-right':
                return { top: '-5px', right: this.arrowPadding +'px' };
            default:
                return { left: '0' };
        }
    }

    @Watch('active')
    private onActiveChange(newValue: boolean) {
        if(!newValue) {
            this.$emit('close');
        }
    }
}

export default VsDropdown;
