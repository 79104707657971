
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
export default class VsIcon extends Vue {

    @Prop({ type: String, required: true })
    public readonly icon!: string;

    @Prop({ type: String, default: '20px' })
    public readonly size!: string;

    @Prop({ type: String, default: 'white' })
    public readonly color!: string;
}
