import { render, staticRenderFns } from "./vs-checkbox.vue?vue&type=template&id=7831ca58"
import script from "./vs-checkbox.vue?vue&type=script&lang=ts"
export * from "./vs-checkbox.vue?vue&type=script&lang=ts"
import style0 from "../../../modules/core/assets/css/vs-radio.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports