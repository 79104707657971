
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
class VsInput extends Vue {

    @Prop({ type: String, default: '' })
    private rules!: string;

    @Prop({ type: String, required: true })
    private name!: string;

    @Prop({ type: String })
    private placeholder?: string;

    @Prop({ type: String, required: true })
    private value!: string;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    @Prop({ type: String })
    private customClass!: string;

    private get text(): string {
        return this.value;
    }
    private set text(value: string) {
        this.$emit('input', value);
    }

    private isActive: boolean = this.value.length > 0;

    private lostFocus(): void {
        this.isActive = false;
    }

}

export default VsInput;
