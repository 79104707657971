
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsToolbar extends Vue {

    @Prop({ type: String, default: 'var(--text-color)' })
    private textColor!: string;

    @Prop({ type: Boolean, default: true })
    private readonly centerTitle!: boolean;

    @Prop({ type: Boolean, default: true })
    private readonly shadow!: boolean;

    @Prop({ type: String, default: 'var(--primary-color)' })
    private readonly backgroundColor!: string;

    @Prop({ type: Boolean, default: true })
    private padNextElement!: boolean;

}

export default VsToolbar;
