
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsIcon } from '../vs-icon';
@Component({ components: { VsIcon } })
class VsRating2 extends Vue {

    @Prop({ type: Number, required: true })
    private value!: number;

    @Prop({ type: String, default: 'var(--primary-color-light)' })
    private color!: string;
    
    private  rate(rate: number) {
        this.value == rate ? this.$emit('input', rate - 1) : this.$emit('input', rate);
    }
}

export default VsRating2;
