
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsIcon } from '../vs-icon';
import { VsTimerInstance } from '.';

@Component({ components: { VsIcon } })
class VsTimer extends Vue implements VsTimerInstance {

    private mounted() {
        if(this.autoStart) {
            this.start();
        }
    }

    @Prop({ type: Number, required: true })
    private value!: number;

    @Prop({ type: Boolean, default: true })
    private autoStart!: boolean;

    private interval: any  = 0;

    private get hours(): number {
        return Math.trunc(this.value / 3600000);
    }

    private get timeString(){
        
        const minutes = Math.trunc((this.value % 3600000) / 60000);
        const seconds = Math.trunc(((this.value % 3600000) % 60000) / 1000);

        return this.hours > 0 ?
            this.hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0') :
            minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    }

    public start(){
        this.stop();
        if(this.value > 0) {
            this.interval = setInterval(() => {
                const valueTemp = this.value - 1000;
                this.$emit('input', valueTemp);
                if(valueTemp <= 0) {
                    this.$emit('finished');
                    this.stop();
                }
            }, 1000);
        }
    }

    public stop(){
        clearInterval(this.interval);
    }

    private beforeDestroy() {
        clearInterval(this.interval);
    }
}

export default VsTimer;
