
import { Component, Vue, Prop } from 'vue-property-decorator';
import LottieAnimation from "@/../node_modules/vue-lottie/src/lottie.vue";

@Component({ components: { LottieAnimation } })
class Lottie extends Vue {

    @Prop({ type: String, required: true })
    public name!: string;

    @Prop({ type: Number, default: 50 })
    public width!: number;

    @Prop({ type: Number, default: 50 })
    public height!: number;

    @Prop({ type: Number, default: 1 })
    public speed!: number;

    @Prop({ type: Boolean, default: false })
    public loop!: boolean;

    public handleAnimation(anim: any) {
        anim.setSpeed(this.speed);
    }

}

export default Lottie;
