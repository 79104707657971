
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsSwitch extends Vue {

    @Prop({ type: Boolean, required: true })
    private value!: boolean;

    private clicked(){
        this.$emit('input', !this.value);
    }
}

export default VsSwitch;
