
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsCircleAvatar extends Vue {

    @Prop({ type: String, required: true })
    private image!: string;

    @Prop({ type: String, default: '40px' })
    private size!: string;

}

export default VsCircleAvatar;
