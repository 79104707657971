import { render, staticRenderFns } from "./vs-textarea.vue?vue&type=template&id=53397940"
import script from "./vs-textarea.vue?vue&type=script&lang=ts"
export * from "./vs-textarea.vue?vue&type=script&lang=ts"
import style0 from "./vs-textarea.vue?vue&type=style&index=0&id=53397940&prod&lang=css"
import style1 from "../vs-input/vs-input.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports