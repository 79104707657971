
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsRadio extends Vue {

    @Prop({ required: true })
    public readonly value!: any;

    @Prop({ required: true })
    public readonly radioValue!: any;

    @Prop({ type: String, required: true })
    public readonly name!: string;

    @Prop({ type: Boolean, default: false })
    public readonly check!: boolean;
}

export default VsRadio;
