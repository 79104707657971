
import { Component, Vue, Prop } from 'vue-property-decorator';

enum FocusColor {
    Dark = 'dark',
    Light = 'light'
}

@Component({ })
class VsFlatButton extends Vue {

    @Prop({ type: Boolean, default: false })
    private readonly disabled!: boolean;

    @Prop({ type: String, default: 'dark' })
    private readonly focusColor!: FocusColor;

    private pressed(e: MouseEvent): void {
        if(!this.disabled) {
            // Elemento
            const el: HTMLElement = this.$refs.circle as HTMLElement;
    
            // Mudan�a do estilo
            el.style.left = 'calc(' + e.offsetX + 'px - 50px)';
            el.style.top = 'calc(' + e.offsetY + 'px - 50px)';
            el.style.animation = 'none';
    
            // Refresh element height
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const height = el.offsetHeight;
            el.style.animation = 'circleshow .4s linear';
    
            this.$emit('onPressed');
        }
    }
}

export default VsFlatButton;
