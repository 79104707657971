
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({ })
class VsCheckbox extends Vue {
    
    @Prop({ type: String, required: true })
    public name!: string;

    @Prop({ required: true })
    public value!: string[] | boolean;

    @Prop({})
    public checkValue!: any;

    public get inputValue() {
        return this.value;
    }

    public set inputValue(value: string[] | boolean) {
        this.$emit('input', value);
    }

    public get checked(){
        return (typeof this.value == 'object' && this.value.includes(this.checkValue)) || (typeof this.value == 'boolean' && this.value == true);
    }
}

export default VsCheckbox;
