
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
// @ts-ignore
import { mask } from 'ke-the-mask';

@Component({ directives: { mask } })
class VsInput extends Vue {

    @Prop({ type: String, default: '' })
    private rules!: string;

    @Prop({ type: String, required: true })
    private name!: string;

    @Prop({ type: String })
    private placeholder?: string;

    @Prop({ type: String, required: true })
    private value!: string;

    @Prop({ type: String, default: '' })
    private mask!: string;

    @Prop({ type: Number, default: 60 })
    private max!: number;

    @Prop({ type: String, default: 'text' })
    private type!: string;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private get text(): string {
        return this.value;
    }
    private set text(value: string) {
        if(value.length <= this.max) {
            this.$emit('input', value);
        }
    }

    private isActive: boolean = false;

    public reset(){
        const provider = this.$refs.provider as InstanceType<typeof ValidationProvider>;
        this.$emit('input', '');
        provider.reset();
    }

    private lostFocus(): void {
        this.$emit('lostFocus');
        if(this.mask.length) {
            if(this.value.length !== this.mask.length) {
                this.$emit('input', '');
            }
        }
        this.isActive = false;
    }

}

export default VsInput;
