import TeacherDTO from '@/modules/academic/DTOs/teachers/TeacherDTO';
import TimeRangeDTO from '@/modules/scheduling/DTOs/TimeRangeDTO';
import SlotDTO from '@/app/DTOs/scheduling/SlotDTO';
import httpPilotar from '@/plugins/httpPilotar';
import { Get } from '@/services/BaseService';

export default class AppTeachersService {

    public static getAvailableForScheduling(date: string, serviceId: string): Promise<TeacherDTO[]>{
        return Get<TeacherDTO[]>(`/app/teachers/get-available-for-scheduling`, { "date": date, "serviceId": serviceId }, { customClient: httpPilotar });
    }

    public static getAvailableSlotsForScheduling(teacherId: string, date: string, selectedSlots: TimeRangeDTO[]): Promise<SlotDTO[]>{
        return Get<SlotDTO[]>(`/app/teachers/get-available-slots-for-scheduling`, { "teacherId": teacherId, "date": date, "selectedSlots": JSON.stringify(selectedSlots) }, { customClient: httpPilotar });
    }

}