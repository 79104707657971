
import { Component, Vue, Prop } from 'vue-property-decorator';
import { VsIcon } from '../vs-icon';

// @ts-ignore
import vClickOutside from 'v-click-outside';

@Component({
    components: { VsIcon },
    directives: { 'clickOutside': vClickOutside.directive }
})
class VsSelect extends Vue {

    @Prop({ type: Boolean, default: false })
    private readonly showErrors!: boolean;

    @Prop({ type: Boolean, default: true })
    private readonly applyFocusStyleWhenFilled!: boolean;

    @Prop({ type: Boolean, default: false })
    private readonly readonly!: boolean;

    @Prop({ type: String, required: true })
    private readonly value!: string;

    @Prop({ type: String, required: true })
    private readonly name!: string;

    @Prop({ type: String, default: '' })
    private readonly rules!: string;

    @Prop({ type: Array, required: true })
    private readonly options!: { name: string; value: string }[];

    @Prop({ type: String, default: '' })
    private readonly placeholder!: string;

    private inFocus: boolean = false;

    private get inputValue() {
        return this.value;
    }
    private set inputValue(value: string) {
        this.inFocus = false;
        this.$emit('input', value);
    }
    private focus() {
        if(!this.readonly) {
            this.inFocus = !this.inFocus;
        }
    }
    lostFocus(){
        this.inFocus = false;
    }

    private get selectedOption(): string {
        const option = this.options?.find(el => el.value == this.inputValue);
        return option != null ? option.name : this.placeholder;
    }
}

export default VsSelect;
